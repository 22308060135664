import { CurrencyType } from "../core/types";

let settings: CurrencySettings = { currencies: [], lastUpdated: "" };
export const getAll: () => CurrencyType[] = () => {
  return settings.currencies.map((c) => c.code) as CurrencyType[];
};

export const get: () => CurrencySettings = () => settings;

export const init: (currencySettings: CurrencySettings) => CurrencyType[] = (
  currencySettings
) => {
  settings = currencySettings;
  return getAll();
};

const list = {
  getAll,
  get,
  init,
};

export default list;
