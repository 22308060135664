const config = {
  onSuccess: () => {
    const event = new CustomEvent("Offline", { detail: "onSuccess" });
    document.body.dispatchEvent(event);
  },
  onUpdate: () => {
    const event = new CustomEvent("Offline", { detail: "onUpdate" });
    document.body.dispatchEvent(event);
  },
  onInstalling: () => {
    const event = new CustomEvent("Offline", { detail: "onInstalling" });
    document.body.dispatchEvent(event);
  },
};
export default config;
