import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";
import PageLoading from "./page/PageLoading";

const FileViewer = React.lazy(() => import("react-file-viewer"));

const Container = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  "& > div": {
    overflow: "auto",
  },
});

const FilePreview = () => {
  const [{ url, fileName }, setFile] = React.useState<any>({});
  const fileType = (fileName && fileName.split(".").pop()) || "";

  React.useEffect(() => {
    const listener = (e: any) => {
      setFile({
        url: e.detail.url,
        fileName: e.detail.fileName,
      });
    };
    document.body.addEventListener("ViewFile", listener, false);
    return () => document.body.removeEventListener("ViewFile", listener);
  }, []);

  if (!url) {
    return null;
  }

  const close = () => {
    setFile({});
  };

  return (
    <Modal
      aria-labelledby="file-viewer"
      aria-describedby="file-viewer-description"
      open
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <div>
          <Container onClick={() => close()}>
            <React.Suspense fallback={<PageLoading />}>
              <FileViewer fileType={fileType} filePath={url} />
            </React.Suspense>
          </Container>
        </div>
      </Fade>
    </Modal>
  );
};

export const viewFile = (url: string, fileName: string) => {
  document.body.dispatchEvent(
    new CustomEvent("ViewFile", {
      detail: {
        url,
        fileName,
      },
    })
  );
};

export default FilePreview;
