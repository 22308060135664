import React from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import LogoImg from "../../../icons/logo_256.png";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.background.paper),
  display: "inline-block",
  "& .root-link": {
    color: "inherit",
    opacity: 0.85,
    "&:hover": {
      opacity: 1,
    },
  },
  "&:hover .root-link, & .root-link:focus": {
    opacity: 1,
  },
}));

const imgStyle = {
  width: 30,
  height: 31,
  marginTop: 7,
};
const Image = styled("img")(imgStyle);

const Logo: React.FC<any> = ({ loading, link }) => {
  const logo = (
    <Root>
      <Grid container alignItems="center">
        <Grid item>
          {loading ? (
            <CircularProgress size={16} sx={imgStyle} color="inherit" />
          ) : (
            <Image src={LogoImg} alt="Logo" />
          )}
        </Grid>
        <Grid item>
          <Typography
            sx={{
              marginLeft: 1,
              marginRight: 2,
              opacity: 0.8,
              fontWeight: 500,
              lineHeight: "32px",
            }}
            className="root-link"
            variant="subtitle1"
          >
            Mr Fin
          </Typography>
        </Grid>
      </Grid>
    </Root>
  );

  if (link) {
    return <Link to="/">{logo}</Link>;
  }

  return logo;
};

Logo.defaultProps = {
  link: false,
};

export default Logo;
