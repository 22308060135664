import React from "react";
import { useTranslation } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import {
  Avatar,
  IconButton,
  Popover,
  Box,
  Typography,
  Button,
  Divider,
  Link,
} from "@mui/material";
import { useAuth } from "../../auth/useAuth";
import { useHistory } from "react-router";
import Language from "../../fin/Language";
import CurrencyDialog from "../../form/input/CurrencyPicker";
import useProfile, { useProfileUpdate } from "../../auth/useProfile";
import { getCurrentVersion } from "../../../services/core/version/utils";

const UserIcon: React.FC<any> = ({ user, size }) => {
  const icon = user.photoURL ? (
    <div style={{ display: "inline-block" }}>
      <Avatar
        src={user.photoURL}
        alt={user.displayName}
        style={{ width: size, height: size }}
      />
    </div>
  ) : (
    <FaUserCircle style={{ width: size, height: size }} />
  );
  return icon;
};

const FinAppBarUser: React.FC<any> = (props) => {
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const { signedIn, signOut, user, loading } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const open = Boolean(anchorEl);

  if (loading) return null;
  if (!signedIn) return null;
  if (!user) return null;

  if (!open)
    return (
      <IconButton
        size="small"
        aria-label={t("userMenu")}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <UserIcon user={user} size={32} />
      </IconButton>
    );

  return (
    <>
      <IconButton
        size="small"
        aria-label={t("userMenu")}
        onClick={() => setAnchorEl(null)}
      >
        <UserIcon user={user} size={32} />
      </IconButton>
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          marginTop: 24,
        }}
      >
        <Box p={2} pt={3} textAlign="center">
          <Box>
            <UserIcon user={user} size={80} />
          </Box>
          <Box p={1}>
            <Typography variant="subtitle1">{user.displayName}</Typography>
            <Typography variant="caption" style={{ opacity: 0.8 }}>
              {user.email}
            </Typography>
          </Box>
          <Box p={1}>
            <Button
              variant="outlined"
              onClick={() => {
                history.push("/settings");
                setAnchorEl(null);
              }}
            >
              {t("settings")}
            </Button>
          </Box>
          <Box pt={3}>
            <SettingsLinks />
          </Box>
        </Box>
        <Divider />
        <Box p={2} textAlign="center">
          <Button
            variant="outlined"
            onClick={() => {
              signOut();
              setAnchorEl(null);
            }}
            style={{ borderRadius: 48 }}
          >
            {t("logOut")}
          </Button>
        </Box>
        <Divider />

        <Box p={2} px={5} textAlign="center">
          <ShortLink
            onClick={() => {
              history.push("/privacy");
              setAnchorEl(null);
            }}
          >
            {t("privacyPolicy")}
          </ShortLink>
          <ShortLinkSeparator />
          <ShortLink
            onClick={() => {
              history.push("/terms");
              setAnchorEl(null);
            }}
          >
            {t("termsOfService")}
          </ShortLink>
        </Box>
        <Box textAlign="center" mt={-2} mb={1}>
          <Typography variant="caption" style={{ opacity: 0.3 }}>
            {t("version") + ": "}
            {getCurrentVersion()}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

const ShortLinkSeparator = () => {
  return (
    <div style={{ display: "inline-block", width: 32, textAlign: "center" }}>
      ·
    </div>
  );
};

const ShortLink: React.FC<any> = ({ children, onClick }) => {
  return (
    <Link
      style={{ opacity: 0.8, cursor: "pointer" }}
      onClick={onClick}
      color="textPrimary"
      variant="caption"
    >
      {children}
    </Link>
  );
};

const SettingsLinks = () => {
  const { t } = useTranslation(["settings"]);
  const [languageOpen, openLanguage] = React.useState(false);
  const [currencyOpen, openCurrency] = React.useState(false);

  const profile = useProfile();
  const updateProfile = useProfileUpdate();
  const darkTheme = (profile.theme && profile.theme.dark) || false;
  const toggleDarkTheme = (dark: boolean) => {
    updateProfile({
      theme: {
        dark,
      },
    });
  };

  return (
    <>
      {currencyOpen && (
        <CurrencyDialog
          onClose={() => openCurrency(false)}
          value={profile.currency}
        />
      )}
      {languageOpen && <Language onClose={() => openLanguage(false)} />}
      <Box px={1}>
        <ShortLink onClick={() => openCurrency((c) => !c)}>
          {t("preferredCurrency")}
        </ShortLink>
        <ShortLinkSeparator />
        <ShortLink onClick={() => openLanguage((l) => !l)}>
          {t("language")}
        </ShortLink>
        <ShortLinkSeparator />
        {!darkTheme ? (
          <ShortLink onClick={() => toggleDarkTheme(true)}>
            {t("darkMode")}
          </ShortLink>
        ) : (
          <ShortLink onClick={() => toggleDarkTheme(false)}>
            {t("lightMode")}
          </ShortLink>
        )}
      </Box>
    </>
  );
};

FinAppBarUser.defaultProps = {};

export default FinAppBarUser;
