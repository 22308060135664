import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { ProfileTheme } from "../../theme";
import { LanguageType } from "../../i18n";
import { CurrencyType } from "../../services/core/types";

type Profile = {
  currency: CurrencyType;
  language: LanguageType;
  etoroId: string;
  theme: ProfileTheme;
};

const useProfile = () => {
  const profile: Profile =
    useSelector((data: any) => data.firebase.profile) || {};

  profile.currency = profile.currency || "USD";
  profile.language = profile.language || "en";
  profile.etoroId = profile.etoroId || "";

  return profile;
};

export const useProfileUpdate = () => {
  const firebase = useFirebase();

  return (data: any) => firebase.updateProfile(data);
};

export default useProfile;
