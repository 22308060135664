import { useTheme, useMediaQuery } from "@mui/material";

export const useLargeDevice = () => {
  const theme = useTheme();

  const width = window.innerWidth;
  const v = theme.breakpoints.values;
  const matches = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: width > v.lg,
  });

  return matches;
};

export const useMobileDevice = () => {
  const theme = useTheme();

  const width = window.innerWidth;
  const v = theme.breakpoints.values;
  const matches = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: width <= v.sm,
  });

  return matches;
};
export const useXSDevice = () => {
  const theme = useTheme();

  const width = window.innerWidth;
  const v = theme.breakpoints.values;
  const matches = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: width <= v.xs,
  });

  return matches;
};
export default useMobileDevice;
