import React from "react";
import { styled } from "@mui/material/styles";
import useDocumentTitle from "./useDocumentTitle";
import ThemeOverride from "../theme/ThemeOverride";
import { PagesContextType } from "./PagesRoot";
import Navigation from "./nav";

const Root = styled("div")({
  height: "calc(100% - 128px)",
});

const Content = styled("div")(({ margin }: any) => ({
  position: "relative",
  margin: margin === "normal" ? 24 : "auto",
  height: "100%",

  maxWidth: "100%",
}));

const Children = styled("div")(({ theme }) => ({
  minHeight: `calc(100% - ${72 + parseInt(theme.spacing(2))}px)`,
  position: "relative",
}));

type Props = PagesContextType & {
  children?: React.ReactNode;
};

const PageContent: React.FC<Props> = ({
  children,
  appbar,
  editable,
  title,
  margin,
  color,
}) => {
  useDocumentTitle(title);

  return (
    <ThemeOverride
      theme={{
        primary: color,
      }}
    >
      <Root>
        <Navigation {...appbar} editable={editable} />
        <Content margin={margin}>
          {children && <Children>{children}</Children>}
        </Content>
      </Root>
    </ThemeOverride>
  );
};

PageContent.defaultProps = {
  margin: "normal",
  tabs: false,
  editable: false,
  title: "",
};

export default PageContent;
