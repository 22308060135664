import moment, { Moment } from "moment";

export const ordinal = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};
export const percentage = (p: number, detailed = false) => {
  if (!detailed) return Math.floor(p * 100) + "%";
  return Math.floor(p * 10000) / 100 + "%";
};

export const percentageDiff = (base: number, current: number) => {
  return (current - base) / base;
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

export const mergeDeep = (target: any, source: any) => {
  const output = { ...target };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
};

export const memoize = (fun: (input?: any) => any) => {
  const cache: any = {};
  return (input: any) => {
    if (cache[input] !== undefined) {
      return cache[input];
    }

    const result = fun(input);
    cache[input] = result;
    return result;
  };
};

export const removeUndefined = (obj: any) => {
  if (typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined) delete obj[key];
      if (typeof obj[key] === "object") removeUndefined(obj[key]);
    });
  }
  return obj;
};

export const getDate = (date?: string | Date | Moment) => {
  if (date instanceof moment) return (date as Moment).utc().format();
  return getMoment(date as string)
    .utc()
    .format();
};
export const getMoment = (date?: string) => {
  "19/04/2019 10:17:32";
  var m1 = moment(date, "DD/MM/YYYY HH:mm:ss");
  if (m1.isValid()) return m1;

  return moment(date);
};
export const getJSDate = (date?: string) => {
  return getMoment(date).toDate();
};
