import { useSelector } from "react-redux";
import { useFirebase, FirebaseReducer } from "react-redux-firebase";
import { persistor } from "../../services/firebase/Provider";
import { useHistory } from "react-router";

export const useAuthState = () => {
  return useSelector(
    (state: any) => state.firebase.auth
  ) as FirebaseReducer.AuthState;
};

export const useAuth = () => {
  const auth = useAuthState();
  const firebase = useFirebase();
  const history = useHistory();

  return {
    userId: auth.uid,
    signedIn: !!auth.uid,
    signIn: (options?: any) => {
      history.push(`/auth`);
    },
    signOut: () => {
      firebase.logout();
      persistor.purge();
    },
    user: auth,
    loading: !auth.isLoaded,
  };
};

export default useAuthState;
