import React from "react";
import { useLocation, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import Tabs from "./Tabs";

const AppBarTabs: React.FC<any> = ({ editable }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  let value: string | false = false;
  if (location.pathname.indexOf("/finances") > -1) value = "finances";
  else if (location.pathname.indexOf("/transactions") > -1)
    value = "transactions";
  else if (location.pathname.indexOf("/analytics") > -1) value = "analytics";

  const tabs = [
    {
      label: t("finances"),
      value: "finances",
    },
    {
      label: t("transactions"),
      value: "transactions",
    },
    {
      label: t("analytics"),
      value: "analytics",
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      value={value}
      onChange={(newValue) => {
        localStorage.setItem("mrfin_tab", newValue);
        history.push(`/${newValue}`);
      }}
      color={editable ? "#FFF" : "primary"}
      variant="standard"
    />
  );
};

export default AppBarTabs;
