import React from "react";
import Tabs from "./Tabs";
import { AppBar, Typography, Box, IconButton } from "@mui/material";
import { useHistory } from "react-router";
import { MdClose, MdChevronRight } from "react-icons/md";

export type FinPageToolbarProps = {
  tabs?: FinPageTabProp[];
  value?: string;
  title?: string;
  back?: {
    to: string;
    label?: string;
  };
};

export type FinPageTabProp = {
  label: string;
  value: string;
  to?: string | ((tab: FinPageTabProp) => string);
};

const FinPageToolbar: React.FC<FinPageToolbarProps> = ({
  tabs,
  value,
  title,
  back,
}) => {
  const history = useHistory();

  return (
    <AppBar color="primary" position="sticky" elevation={1}>
      {title && back && (
        <Box p={1} pl={4} pb={0} pt={0}>
          <Typography
            variant="subtitle1"
            display="inline"
            style={{ verticalAlign: "middle" }}
          >
            {back.label ? (
              <>
                <span style={{ marginRight: 4 }}>{back.label}</span>
                <MdChevronRight style={{ paddingTop: 5 }} />
                <span style={{ marginRight: 4 }} />
              </>
            ) : null}
            {title}
          </Typography>
          {back && (
            <>
              <span style={{ lineHeight: "50px" }} />
              <div style={{ position: "absolute", right: 16, top: 0 }}>
                <IconButton
                  color="inherit"
                  onClick={() => history.push(back.to)}
                  size="large"
                >
                  <MdClose />
                </IconButton>
              </div>
            </>
          )}
        </Box>
      )}
      {title && !back && (
        <Box p={1} pl={4} pb={tabs ? 0 : 1} pt={tabs ? 2 : 1}>
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
      )}
      {tabs && (
        <Box pl={3 - 0.5}>
          <Tabs
            tabs={tabs}
            value={value || false}
            onChange={(newValue) => {
              const tab = tabs.find((t) => t.value === newValue);
              if (tab && tab.to) {
                const to = typeof tab.to === "function" ? tab.to(tab) : tab.to;
                history.push(to);
              }
            }}
            color="#FFF"
          />
        </Box>
      )}
    </AppBar>
  );
};

export default FinPageToolbar;
