import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore"; // <- needed if using firestore
import "firebase/compat/functions"; // <- needed if using httpsCallable
import "firebase/compat/app-check";

const fbConfig = {
  apiKey: "AIzaSyD_811FKQqn4TFw14ae01ZnrRcXtaeULvY",
  authDomain: "mrfin.app",
  databaseURL: "https://mr-fin-81c83.firebaseio.com",
  projectId: "mr-fin-81c83",
  storageBucket: "mr-fin-81c83.appspot.com",
  messagingSenderId: "557086796294",
  appId: "1:557086796294:web:708b4a369c0a4323e4f187",
  measurementId: "G-85V04DLGT9",
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore
firebase.functions(); // <- needed if using httpsCallable

firebase.appCheck().activate("6LemX4AfAAAAAFTkim1RJHX-kvbkcNsY654Pj52e", true); // <- Recaptcha

firebase
  .firestore()
  .enablePersistence({
    synchronizeTabs: true,
  })
  .catch((err) => {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

firebase.analytics().logEvent("app_boot");

export default firebase;
