import React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import useAuthState from "./useAuth";
import Currencies from "../../services/currencies";

type Props = RouteProps & {
  component: any;
};

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => {
  const auth = useAuthState();
  Currencies.rates.useRates();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.uid ? (
          <Component {...props} />
        ) : auth.uid ? null : (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
