import { useHistory } from "react-router";
import React from "react";
import PageLoading from "./PageLoading";

const RedirectPage: React.FC<any> = ({ to }) => {
  const history = useHistory();
  const replace = history.replace;
  React.useEffect(() => {
    replace(to);
  }, [replace, to]);

  return <PageLoading />;
};

export default RedirectPage;
