import React from "react";
import PageLoading from "./components/page/PageLoading";
import PageError from "./components/page/PageError";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error,
      errorInfo
    });
  }

  render() {
    if (this.state.error) {
      const { error, errorInfo } = this.state;
      let message = error.stack;
      if (message.indexOf("(") > -1) {
        message = error.stack.split("(")[0];
      }

      return (
        <PageError
          title={error.message}
          message={message}
          details={errorInfo.componentStack}
        />
      );
    }

    return (
      <React.Suspense fallback={<PageLoading />}>
        {this.props.children}
      </React.Suspense>
    );
  }
}

export default ErrorBoundary;
