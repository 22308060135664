import React from "react";
import { MdClose } from "react-icons/md";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Link,
  Breadcrumbs,
  Typography,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FinPageToolbarProps } from "./PageToolbar";
import { useTranslation } from "react-i18next";

export type AppBarBreadcrumbsProp = {
  label: string | null;
  to?: string;
}[];

const Root = styled("div")({
  opacity: 0.33,
  "&:hover": {
    opacity: 1,
  },
});

type AppBarBreadcrumbsProps = {
  back?: FinPageToolbarProps["back"];
  breadcrumbs?: AppBarBreadcrumbsProp;
  title?: string;
};

const AppBarBreadcrumbs: React.FC<AppBarBreadcrumbsProps> = ({
  back,
  breadcrumbs,
  title,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <div>
        <Root>
          <Breadcrumbs separator="›" aria-label="breadcrumb" color="inherit">
            {breadcrumbs &&
              breadcrumbs.map((b) => {
                if (!b.label) return null;
                if (b.to) {
                  return (
                    <Tooltip
                      key={b.to}
                      title={t("gotopage", { page: b.label }) || ""}
                    >
                      <Link
                        color="inherit"
                        variant="subtitle2"
                        component={RouterLink}
                        style={{ opacity: 0.8 }}
                        to={b.to}
                      >
                        {b.label}
                      </Link>
                    </Tooltip>
                  );
                }
                return (
                  <Typography key={b.label} variant="subtitle2">
                    {b.label}
                  </Typography>
                );
              })}
          </Breadcrumbs>
        </Root>
        <Typography variant="subtitle1">{title}</Typography>
      </div>
      <Grid item xs />
      {back && (
        <IconButton
          color="inherit"
          onClick={() => history.push(back.to)}
          size="large"
        >
          <MdClose />
        </IconButton>
      )}
    </>
  );
};

export default AppBarBreadcrumbs;
