import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  IconButton,
  useTheme,
  Theme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { MdClose } from "react-icons/md";

export type FinModalProps = {
  title?: React.ReactNode | string | null;
  content?: React.ReactNode | string | null;
  submit?: string | null;
  onSubmit?: () => any;
  disabled?: boolean;
  closable?: boolean;

  theme?: Theme;
};

type FinModalContextProps = {
  modal: FinModalProps | null;
  openModal: (modal: FinModalProps | null) => any;
  closeModal: () => any;
};

const Context = React.createContext<FinModalContextProps>({
  modal: null,
  openModal: (modal) => {
    alert("n/a");
  },
  closeModal: () => {
    alert("n/a");
  },
});

const ModalProvider: React.FC<any> = ({ children }) => {
  const [modal, openModal] =
    React.useState<FinModalContextProps["modal"]>(null);

  const closeModal = () => {
    openModal(null);
  };
  const handleAction = () => {
    if (!modal) return;
    if (!modal.onSubmit) {
      return openModal(null);
    }

    const returnVal = modal.onSubmit();

    if (typeof returnVal === "object" && returnVal.then) {
      openModal({
        ...modal,
        disabled: true,
      });
      returnVal.then(() => {
        openModal(null);
      });
    } else {
      openModal(null);
    }
  };

  return (
    <Context.Provider value={{ modal, openModal, closeModal }}>
      {modal && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={modal.theme || {}}>
            <Dialog
              open={Boolean(modal)}
              onClose={() => openModal(null)}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
            >
              {openModal ? (
                <IconButton
                  aria-label="close"
                  onClick={() => openModal(null)}
                  style={{ position: "absolute", top: 8, right: 8, zIndex: 2 }}
                  size="large"
                >
                  <MdClose />
                </IconButton>
              ) : null}
              {modal.title && (
                <DialogTitle id="dialog-title">{modal.title}</DialogTitle>
              )}
              {modal.content && (
                <DialogContent>
                  <DialogContentText id="dialog-description" component="div">
                    {modal.content}
                  </DialogContentText>
                </DialogContent>
              )}
              <DialogActions>
                <Button
                  disabled={modal.disabled}
                  onClick={handleAction}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  {modal.submit}
                </Button>
              </DialogActions>
            </Dialog>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
      {children}
    </Context.Provider>
  );
};

export const useModal = () => {
  const ctx = React.useContext(Context);
  const theme = useTheme();

  return {
    open: (modal: FinModalContextProps["modal"]) => {
      if (modal) {
        modal.theme = theme;
        ctx.openModal(modal);
      }
    },
    close: ctx.closeModal,
    isOpen: !!ctx.modal,
  };
};

export default ModalProvider;
