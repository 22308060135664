import React from "react";
import { Snackbar, IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";

const DELAY = 1000;

const Offline: React.FC<{}> = () => {
  const [message, setMessage] = React.useState("");
  React.useEffect(() => {
    const onSuccess = () => setMessage("Mr Fin is available offline");

    const listener = (e: any) => {
      if (e.detail === "onSuccess") onSuccess();
    };
    document.body.addEventListener(
      "Offline",
      (e) => {
        setTimeout(() => {
          listener(e);
        }, DELAY);
      },
      false
    );

    return () => document.body.removeEventListener("Offline", listener);
  }, [setMessage]);

  const onClose = () => setMessage("");
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={!!message}
      autoHideDuration={6000}
      onClose={onClose}
      ContentProps={{
        "aria-describedby": "offline-notification-id",
      }}
      message={<span id="offline-notification-id">{message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
          size="large"
        >
          <MdClose />
        </IconButton>,
      ]}
    />
  );
};

export default Offline;
