import firebase from "../../firebase/app";
import packageJSON from "../../../../package.json";

export type AppVersionInfo = {
  /** Version string */
  version: string;
  /** Date released */
  updated: string;
};

export type AppVersionUpdateInfo = {
  /** Current version string, from package.json */
  current: AppVersionInfo["version"];
  /** Latest version, from db */
  update: AppVersionInfo;
};

export const getCurrentVersion = () => {
  return packageJSON.version;
};

export const getLatestVersion = async () => {
  const financeCol = firebase.firestore().collection("/version");
  const doc = financeCol.doc("info");
  const versionInfo = await doc.get();
  const deployedVersion = versionInfo.data() as AppVersionInfo;
  return deployedVersion;
};

export const updateAvailable = async () => {
  const current = getCurrentVersion();
  const update = await getLatestVersion();

  const result = versionCompare(update.version, current);
  if (result === 1)
    return {
      current,
      update,
    };
  return null;
};

/** Compare two dotted version strings (like '10.2.3').
 * @returns {Integer} 0: v1 == v2, -1: v1 < v2, 1: v1 > v2
 */
function versionCompare(v1: string, v2: string) {
  var v1parts = ("" + v1).split("."),
    v2parts = ("" + v2).split("."),
    minLength = Math.min(v1parts.length, v2parts.length),
    p1,
    p2,
    i;
  for (i = 0; i < minLength; i++) {
    p1 = parseInt(v1parts[i], 10);
    p2 = parseInt(v2parts[i], 10);
    if (isNaN(p1)) {
      p1 = v1parts[i];
    }
    if (isNaN(p2)) {
      p2 = v2parts[i];
    }
    if (p1 === p2) {
      continue;
    } else if (p1 > p2) {
      return 1;
    } else if (p1 < p2) {
      return -1;
    }
    // one operand is NaN
    return NaN;
  }
  // The longer tuple is always considered 'greater'
  if (v1parts.length === v2parts.length) {
    return 0;
  }
  return v1parts.length < v2parts.length ? -1 : 1;
}
