import React from "react";
import Dialog from "@mui/material/Dialog";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { LanguageType, Languages, getLanguage } from "../../i18n";
import { useFirebase } from "react-redux-firebase";
import moment from "moment";

type Props = {
  onChange?: (newValue: LanguageType) => any;
  onClose: () => any;
};

const Language: React.FC<Props> = ({ onChange, onClose }) => {
  const { i18n } = useTranslation();
  const crtLanguage = i18n.language as LanguageType;
  const firebase = useFirebase();

  const handleChange = (lang: LanguageType) => {
    if (onChange) {
      onChange(lang);
      onClose();
    } else {
      i18n.changeLanguage(lang);
      moment.locale(lang);
      firebase.updateProfile({
        language: lang,
      });
      onClose();
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <List component="nav" aria-label="pick new currency">
        {Languages.map((lang) => {
          const language = getLanguage(lang);
          if (!language) return null;

          return (
            <ListItem
              key={lang}
              button
              selected={lang === crtLanguage}
              onClick={() => {
                handleChange(lang);
              }}
            >
              <ListItemIcon>
                <CountryFlag
                  svg
                  countryCode={language.country}
                  style={{ width: 24 }}
                />
              </ListItemIcon>
              <ListItemText primary={language.label} />
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
};

export default Language;
