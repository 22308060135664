import React from "react";
import { useProfileTheme } from "../theme";
import moment from "moment";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";
import PageLoading from "./page/PageLoading";
import useProfile from "./auth/useProfile";
import ModalProvider from "./page/modals/ModalProvider";
import Version from "./version";
import FilePreview from "./FilePreview";

const Provider: React.FC = ({ children }) => {
  const profile = useProfile();
  let language = "en";
  if (profile && profile.language) language = profile.language;

  React.useEffect(() => {
    if (i18n.language !== language) i18n.changeLanguage(language);
    moment.locale(language);
    if (language !== "en") import("moment/locale/" + language);
  }, [language]);

  const theme = useProfileTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Version />
        <CssBaseline />
        <FilePreview />
        <React.Suspense fallback={<PageLoading />}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <I18nextProvider i18n={i18n}>
              <ModalProvider>{children}</ModalProvider>
            </I18nextProvider>
          </LocalizationProvider>
        </React.Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Provider;
