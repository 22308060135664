import React from "react";
import { CurrencyType } from "../../services/core/types";

const ReactCurrencyFlag = React.lazy(() => import("react-currency-flags"));

type Props = {
  currency: CurrencyType;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

const CurrencyFlag: React.FC<Props> = ({ currency, ...props }) => {
  if (currency === "BTC")
    return (
      <img
        {...props}
        src="https://i.ibb.co/yfwgN6d/images-Custom.png"
        alt="Bitcoin"
      />
    );

  if (currency === "XAU")
    return (
      <img
        {...props}
        src="https://i.ibb.co/Vt4QrRW/etf-investment-guide-gold-Custom.jpg"
        alt="Gold"
      />
    );

  return (
    <React.Suspense fallback={null}>
      <ReactCurrencyFlag currency={currency} {...props} />
    </React.Suspense>
  );
};

export default CurrencyFlag;
