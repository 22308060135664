import formatter from "./formatter";
import rates from "./rates";
import { getAll, get } from "./list";
import { CurrencyType, MoneyType } from "../core/types";
import { percentage } from "../core/utils";
export * from "../core/types.d";

export const formatCurrency = (amountValue: number, currency: CurrencyType) => {
  return formatter.amountCurrency(amountValue, currency, true);
};

export const formatPercentage = (currentValue: number, baseValue: number) => {
  const diff = (currentValue - baseValue) / baseValue;
  return percentage(diff, true);
};

export const formatCurrencySign = (
  amountValue: number,
  currency: CurrencyType
) => {
  return formatter.amountCurrency(amountValue, currency, true, true);
};

export const convertAndFormat = (
  amountValue: number,
  amountCurrency: CurrencyType,
  targetCurrency: CurrencyType
) => {
  const value = rates.convert(amountValue, amountCurrency, targetCurrency);
  return formatter.amountCurrency(value, targetCurrency, true);
};

export const isMoney = (obj: any) => {
  if (typeof obj !== "object") return false;
  if (typeof obj.amount !== "number") return false;
  if (typeof obj.currency !== "string") return false;
  return true;
};

export const convert = (
  money: MoneyType | undefined,
  currency: CurrencyType
) => {
  if (!money) return 0;
  if (!isMoney(money)) return 0;
  return rates.convert(money.amount, money.currency, currency);
};

const Currencies = {
  formatter,
  format: formatCurrencySign,
  convert,
  convertAndFormat,
  rates,
  listSupported: getAll,
  listSupportedInfo: get,
  isMoney,
};

export default Currencies;
