import React from "react";
import { Grid, Toolbar, AppBar } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMobile from "../../theme/useMobile";
import AppBarTabs from "./AppBarTabs";
import FinAppBarUser from "./AppBarUser";
import PageToolbar, { FinPageToolbarProps } from "./PageToolbar";
import Logo from "./Logo";
import MobileAppBar from "./MobileAppBar";
import Breadcrumbs, { AppBarBreadcrumbsProp } from "./Breadcrumbs";

export type FinAppBarProps = {
  tabs?: FinPageToolbarProps["tabs"];
  tabValue?: FinPageToolbarProps["value"];
  title?: FinPageToolbarProps["title"];

  loading?: boolean;

  back?: FinPageToolbarProps["back"];

  breadcrumbs?: AppBarBreadcrumbsProp;

  editable?: boolean;
};

const LogoEnd = styled("div")({
  width: 31,
  display: "inline-block",
});

const FinAppBar: React.FC<FinAppBarProps> = ({
  tabs,
  tabValue,
  title,
  back,
  breadcrumbs,
  loading,
  editable,
}) => {
  const isXS = useMobile();

  if (breadcrumbs) {
    return (
      <AppBar color="primary" position="relative">
        <Toolbar sx={{ zIndex: 9999, height: 56 }}>
          <Breadcrumbs back={back} breadcrumbs={breadcrumbs} title={title} />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <>
      <AppBar
        color="primary"
        position="relative"
        elevation={editable ? 0 : 2}
        sx={{
          bgcolor: "background.paper",
          // color: theme.palette.getContrastText(theme.palette.background.paper),
        }}
      >
        <Toolbar
          sx={{
            height: 48,
            minHeight: "48px !important",
          }}
        >
          <Logo link loading={loading} />
          <LogoEnd />
          {!isXS && <AppBarTabs editable={editable} />}
          <Grid item xs />
          <FinAppBarUser />
        </Toolbar>
      </AppBar>
      <PageToolbar tabs={tabs} value={tabValue} title={title} back={back} />
      {isXS && <MobileAppBar />}
    </>
  );
};

FinAppBar.defaultProps = {
  loading: false,
};

export default FinAppBar;
