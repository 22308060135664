import React from "react";
import Logo from "../../icons/logo_256.png";
import { styled } from "@mui/material/styles";

type PageLoadingProps = {
  text?: string;
};

const PageLoading: React.FC<PageLoadingProps> = ({ text }) => {
  return (
    <Root>
      <Background />
      {text && <Text>{text}</Text>}
    </Root>
  );
};

const SIZE = 72;

const Root = styled("div")({
  height: "100%",
});

const Background = styled("div")({
  position: "fixed",
  top: "50%",
  left: "50%",
  width: SIZE,
  height: SIZE,
  marginLeft: -SIZE / 2,
  marginTop: -SIZE / 2,

  overflow: "hidden",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundImage: `url(${Logo})`,
  zIndex: -1,
});

const Text = styled("div")(({ theme }) => ({
  position: "fixed",
  marginTop: SIZE / 2 + parseInt(theme.spacing(2)),
  marginLeft: -SIZE / 2 - parseInt(theme.spacing(1)),
  top: "50%",
  left: "50%",
  opacity: 0.8,
}));

export default PageLoading;
