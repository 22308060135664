import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Button } from "@mui/material";
import img from "../../icons/Error.jpg";
import PageContent from "./PageContent";

const Img = styled("div")({
  overflow: "hidden",
  backgroundColor: "transparent",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  display: "inline-block",

  minHeight: 320,
  width: "100%",
  height: "100%",
});

const PageError: React.FC<any> = ({ title, message, details }) => {
  const { t } = useTranslation();
  const [isDetailsVisible, toggleDetailsVisibility] = React.useState(false);

  return (
    <PageContent title={t("error")}>
      <Grid container justifyContent="center" direction="column">
        <Grid item>
          <Img style={{ backgroundImage: `url(${img})` }} />
          {!isDetailsVisible && (
            <>
              <div style={{ textAlign: "center" }}>
                <Typography variant="h5" gutterBottom>
                  {title}
                </Typography>
                <Typography>{message}</Typography>
              </div>

              {details && (
                <div style={{ textAlign: "center" }}>
                  <Button onClick={() => toggleDetailsVisibility((v) => !v)}>
                    View details
                  </Button>
                </div>
              )}
            </>
          )}

          {isDetailsVisible && (
            <div>
              <Button onClick={() => toggleDetailsVisibility((v) => !v)}>
                Go back
              </Button>
              <div>
                <Typography variant="h6" gutterBottom>
                  {title}
                </Typography>
                <Typography>{details}</Typography>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </PageContent>
  );
};

PageError.defaultProps = {
  title: "Unexpected Error",
  message:
    "Looks like something went wrong on our end. Head back to the home page.",
  details: null,
  buttonText: "Go back",
};

export default PageError;
