import React from "react";
import { Theme, ThemeProvider } from "@mui/material";
import { ProfileTheme, createAppTheme } from "../../theme";

const THEMES: { [key: string]: Theme } = {};
const getKey = (t: ProfileTheme) => t.dark + "|" + t.primary;
const getTheme = (theme: ProfileTheme) => {
  const key = getKey(theme);
  if (THEMES[key]) return THEMES[key];

  const newTheme = createAppTheme(theme);
  THEMES[key] = newTheme;
  return newTheme;
};

const createThemeOverride = (overrides: Partial<ProfileTheme>) => {
  return (parentTheme: Theme) => {
    const newProfileTheme = {
      ...parentTheme.profile,
      ...overrides,
    };

    return getTheme(newProfileTheme);
  };
};

const ThemeOverride: React.FC<{
  theme?: Partial<ProfileTheme>;
  children: any;
}> = ({ theme, children }) => {
  if (!theme) return children;
  if (!Object.values(theme).filter((v) => typeof v !== "undefined").length)
    return children;

  const customTheme = createThemeOverride(theme);

  return React.createElement(ThemeProvider, {
    theme: customTheme,
    children,
  });
};

export default ThemeOverride;
