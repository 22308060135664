import React from "react";
import { createTheme } from "@mui/material/styles";
import useProfile from "./components/auth/useProfile";

declare module "@mui/material/styles" {
  interface Theme {
    profile: ProfileTheme;
  }
  interface ThemeOptions {
    profile: ProfileTheme;
  }
}

export const createAppTheme = (theme: ProfileTheme) =>
  createTheme({
    profile: theme,
    palette: {
      mode: theme.dark ? "dark" : "light",
      primary: {
        main: theme.primary,
      },
    },
  });

const defaultOptions: ProfileTheme = {
  dark: false,
  primary: "rgb(40, 128, 44)",
};
const defaultTheme = createAppTheme(defaultOptions);

export type ProfileTheme = {
  dark: boolean;
  primary: string;
};

export const useProfileTheme = () => {
  const [theme, setTheme] = React.useState(defaultTheme);

  const profile = useProfile();

  React.useEffect(() => {
    if (!profile?.theme) return;

    const profileTheme: ProfileTheme = { ...defaultOptions, ...profile.theme };

    const newTheme = createAppTheme(profileTheme);
    setTheme(newTheme);
  }, [profile]);

  return theme;
};

export default defaultTheme;
