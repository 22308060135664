import React from "react";
import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  TabsProps as MuiTabProps,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type TabsProps = {
  tabs: {
    value: string;
    label: string;
  }[];
  onChange: (tabValue: string) => any;
  value: string | false;
  color?: string;
  variant?: MuiTabProps["variant"];
};

const ChipTab = React.forwardRef<MuiTabProps>(
  ({ children, variant, className, ...rest }: any, ref) => {
    const isSelected = rest["aria-selected"];
    return (
      <Chip
        ref={ref}
        label={children}
        {...rest}
        sx={{
          marginRight: 1,
          opacity: isSelected ? 1 : 0.7,
          borderColor: "transparent",
        }}
        variant={isSelected ? "filled" : "outlined"}
      />
    );
  }
);

const Tabs: React.FC<TabsProps> = ({
  tabs,
  value,
  onChange,
  color,
  variant,
}) => {
  const { t } = useTranslation();

  let textColor: any = color;
  let indicatorColor: any = color;
  if (color && color.indexOf("#") === 0) {
    textColor = undefined;
    indicatorColor = undefined;
  }

  const hasSelection = Boolean(tabs.find((t) => t.value === value));

  return (
    <MuiTabs
      orientation="horizontal"
      value={hasSelection ? value : false}
      onChange={(e, newValue) => onChange(newValue)}
      variant={variant}
      aria-label={t("navigation")}
      textColor={textColor}
      indicatorColor={indicatorColor}
      sx={{
        pt: 1,
        pb: 1,
        minHeight: 32,
      }}
      TabIndicatorProps={{
        style: {
          display: "none",
        },
      }}
    >
      {tabs.map((t) => (
        <MuiTab
          key={t.value}
          label={t.label}
          value={t.value}
          component={ChipTab}
          aria-selected={t.value === value}
        />
      ))}
    </MuiTabs>
  );
};

Tabs.defaultProps = {
  variant: "scrollable",
};

export default Tabs;
