import React from "react";
import Offline from "./Offline";
import VersionInfo from "./VersionInfo";

const Version = () => {
  return (
    <>
      <Offline />
      <VersionInfo />
    </>
  );
};

export default Version;
