import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  Dialog,
  styled,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useFirebase } from "react-redux-firebase";
import { CurrencyType } from "../../../services/core/types";
import Currencies from "../../../services/currencies";
import CurrencyFlag from "../../money/CurrencyFlag";
import useMobileDevice from "../../theme/useMobile";

type Props = {
  value?: CurrencyType;
  onChange?: (newValue: CurrencyType, amount: number) => any;
  onClose: () => any;
  defaultAmount?: number;
};

const MobileSheet = styled(Drawer)({
  "& .MuiDrawer-paper": {
    maxHeight: "60vh",
    maxWidth: 400,
    margin: "auto",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
});

const DesktopSheet = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: 24,
    padding: 24,
  },
});

const CurrencySheet = (props: any) => {
  const isMobile = useMobileDevice();

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const t = setTimeout(() => {
      setOpen(true);
    }, 1);
    return () => {
      clearTimeout(t);
    };
  }, []);

  if (isMobile)
    return (
      <MobileSheet open={open} variant="temporary" anchor="bottom" {...props} />
    );
  return <DesktopSheet open={open} {...props} />;
};

const CurrencyPickerDialog: React.FC<Props> = ({
  value,
  defaultAmount,
  onChange,
  onClose,
}) => {
  const info = Currencies.listSupportedInfo();
  const firebase = useFirebase();

  const handleChange = (currency: CurrencyType) => {
    if (onChange) onChange(currency, amount);
    else {
      firebase.updateProfile({
        currency,
      });
    }
  };

  const [amount, setAmount] = React.useState(defaultAmount || 1);
  const setAmountValue = (e: any) => {
    const val = parseInt(e.currentTarget.value);
    const isNaN = Number.isNaN(val);
    if (isNaN) return;
    setAmount(val);
  };

  return (
    <CurrencySheet onClose={onClose}>
      <TextField
        variant="standard"
        value={amount}
        onChange={setAmountValue}
        InputProps={{
          startAdornment: value && (
            <InputAdornment position="start">
              {Currencies.formatter.symbol(value)}
            </InputAdornment>
          ),
        }}
        sx={{
          paddingTop: {
            xs: 2,
            md: 0,
          },
          paddingLeft: {
            xs: 2,
            md: 0,
          },
        }}
      />
      <List
        component="nav"
        aria-label="pick new currency"
        sx={{ maxHeight: 350, overflowY: "auto", marginTop: 1 }}
      >
        {info.currencies.map((currency) => (
          <ListItem
            key={currency.code}
            button
            selected={currency.code === value}
            onClick={() => {
              handleChange(currency.code as CurrencyType);
              onClose();
            }}
          >
            <ListItemIcon>
              <CurrencyFlag
                currency={currency.code as CurrencyType}
                width={24}
                style={{
                  marginTop: -16,
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {currency.description}
                  <span
                    style={{
                      fontSize: ".8rem",
                      opacity: 0.4,
                      left: 14,
                      bottom: 14,
                      position: "absolute",
                    }}
                  >
                    {currency.code}
                  </span>
                </>
              }
              secondary={
                <>
                  {value &&
                    Currencies.convertAndFormat(amount, value, currency.code)}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </CurrencySheet>
  );
};

export default CurrencyPickerDialog;
