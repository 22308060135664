import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Logo from "../../icons/logo_256.png";
import {
  updateAvailable,
  AppVersionUpdateInfo,
} from "../../services/core/version/utils";
import { useXSDevice } from "../theme/useMobile";

const VersionInfo: React.FC<{}> = () => {
  const { t } = useTranslation();
  const [update, setUpdate] = React.useState<AppVersionUpdateInfo | null>(null);
  React.useEffect(() => {
    updateAvailable().then((updateInfo) => {
      setUpdate(updateInfo);
    });
  }, []);

  const [installingUpdateStatus, setInstallingUpdate] = React.useState<
    "INSTALLED" | "INSTALLING" | null
  >(null);
  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.detail === "onInstalling") setInstallingUpdate("INSTALLING");
      else if (e.detail === "onUpdate") setInstallingUpdate("INSTALLED");
    };

    document.body.addEventListener("Offline", listener, false);

    return () => document.body.removeEventListener("Offline", listener);
  }, [setInstallingUpdate]);

  const isXs = useXSDevice();
  const isInstalled = installingUpdateStatus === "INSTALLED";
  const isInstalling = installingUpdateStatus === "INSTALLING";

  if (update) {
    let when = moment(update.update.updated).fromNow();
    return (
      <Dialog aria-labelledby="finances-data-loader" open disableEscapeKeyDown>
        <DialogContent>
          <Grid container direction={isXs ? "column" : "row"}>
            <Grid item>
              <img
                src={Logo}
                alt="Logo"
                width="80"
                height="82"
                style={{
                  width: 80,
                  height: 82,
                  marginTop: 16,
                }}
              />
              {isInstalling && (
                <CircularProgress
                  size={75}
                  color="success"
                  sx={{ position: "absolute", left: 26, top: 41 }}
                />
              )}
            </Grid>
            <Grid item xs>
              <DialogTitle
                id="finances-data-loader"
                style={{ paddingBottom: 0 }}
              >
                {isInstalling && t("updateInstalling")}
                {isInstalled && t("updateInstalled")}
                {!installingUpdateStatus && t("updateAvailable")}
              </DialogTitle>
              <DialogContent style={{ marginBottom: 8 * 4 }}>
                <Typography variant="body2" gutterBottom>
                  {isInstalled && t("updateTip")}
                </Typography>
                <Typography variant="caption">
                  {installingUpdateStatus !== "INSTALLED" && (
                    <>
                      {t("version")}: {update.current}
                    </>
                  )}
                  <br />
                  {t("latestVersion")}: {update.update.version} ({when})
                </Typography>
              </DialogContent>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  return null;
};

export default VersionInfo;
