import firebase from "../firebase/app";
import { CurrencyType } from "../core/types";
import { init } from "./list";
import React, { useState } from "react";

type RatesType = {
  [currencyPair: string]: number;
};
let LOADED = false;
let LOADING: false | Promise<any> = false;
let RATES: RatesType = {
  "USD-RON": 4,
  "USD-EUR": 1,
  "USD-SEK": 8,
  "EUR-USD": 1,
  "EUR-RON": 4.5,
  "EUR-SEK": 10,
  "RON-USD": 0.3,
  "RON-EUR": 0.25,
  "RON-SEK": 2,
  "SEK-USD": 0.1,
  "SEK-EUR": 0.1,
  "SEK-RON": 0.5,
};

const STORAGE_RATES = "mrfin-fx-rates";
const STORAGE_SETTINGS = "mrfin-fx-settings";
const loadFromStorage = () => {
  const stored = localStorage.getItem(STORAGE_RATES);
  if (stored) {
    RATES = JSON.parse(stored);
  }

  const settings = localStorage.getItem(STORAGE_SETTINGS);
  if (settings) {
    init(JSON.parse(settings));
  }
};
loadFromStorage();
const saveToStorage = () => {
  localStorage.setItem(STORAGE_RATES, JSON.stringify(RATES));
};

const pair = (from: CurrencyType | string, to: CurrencyType | string) =>
  `${from}-${to}`;

type Deferred = {
  promise: Promise<any>;
  resolve: (value: any) => any;
  reject: (value: any) => any;
};
const deferred: () => Deferred = () => {
  let resolve: any, reject: any;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return { promise, resolve, reject };
};

const load = async () => {
  const c = firebase.firestore().collection("/currency");
  const settingsDoc = c.doc("settings");
  const ratesDoc = c.doc("rates");
  const def = deferred();
  LOADING = def.promise;
  const settings = (await settingsDoc.get()).data() as CurrencySettings;
  const rates = (await ratesDoc.get()).data() as CurrencyRates;

  if (settings.currencies?.length) {
    init(settings);
    localStorage.setItem(STORAGE_SETTINGS, JSON.stringify(settings));
  }

  const newRates: RatesType = {};
  if (rates) {
    const passed = [];
    //auto generate the other rates
    for (const rate in rates) {
      const [from, to] = rate.split("-");
      // 1 eur = 1.16 usd
      //   1 usd = 0.86 eur
      // 1 eur = 4.95 ron
      //   1 ron = 0.20 eur
      newRates[pair(from, to)] = rates[rate];
      newRates[pair(to, from)] = 1 / rates[rate];

      //use eur as middle man
      //   usd to ron ? 4.27 = 4.95/1.16
      //   ron to usd ? 0.23 = 1.16/4.95
      passed.forEach((pass) => {
        newRates[pair(pass, to)] =
          newRates[pair(from, to)] / newRates[pair(from, pass)];
        newRates[pair(to, pass)] = 1 / newRates[pair(pass, to)];
      });
      passed.push(to);
    }
    RATES = newRates;
  }
  LOADED = true;
  LOADING = false;
  def.resolve(false);
  saveToStorage();
};

const refresh = async () => {
  await firebase.functions().httpsCallable("refreshCurrencies")();
  await load();
};

const convert = (
  amount: number,
  currency: CurrencyType,
  toCurrency: CurrencyType
) => {
  if (currency === toCurrency) return amount;

  const pairId = pair(currency, toCurrency);
  if (!RATES[pairId]) {
    if (LOADED) console.error("Unknown currency pair " + pairId);
    return 0;
  }

  return RATES[pairId] * amount;
};

const useRates = () => {
  const [rates, setRates] = useState(RATES);
  const unmounted = React.useRef(false);

  React.useEffect(() => {
    async function check() {
      if (!LOADED) {
        if (LOADING) {
          await LOADING;
        } else {
          await load();
        }

        if (!unmounted.current) {
          setRates(RATES);
        }
      }
    }
    check();
  }, []);
  React.useEffect(
    () => () => {
      unmounted.current = true;
    },
    []
  );

  return rates;
};

const rates = {
  /**
   * Gets the new currency pair rates from
   * a remote server and save to local storage
   */
  refresh,
  /** Currency calculator */
  convert,

  /** Hook to get the rates */
  useRates,
};
export default rates;
