import React from "react";
import { capitalize } from "../../services/core/utils";

const SEPARATOR = " - ";

const useDocumentTitle = (title?: string | (string | undefined)[]) => {
  React.useEffect(() => {
    let newTitle = "";
    if (!title) {
      newTitle = "";
    } else if (typeof title === "string") {
      newTitle = capitalize(title);
    } else {
      newTitle = title
        .filter((s) => !!s)
        .map((s) => capitalize(s as string))
        .join(SEPARATOR);
    }

    if (newTitle) {
      newTitle += SEPARATOR;
    }
    newTitle += "Mr Fin";

    document.title = newTitle;
  }, [title]);
};

export default useDocumentTitle;
