import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export type LanguageType = "en" | "ro" | "fr";
export const Languages: LanguageType[] = ["en", "ro", "fr"];
const LanguagesInfo = ["English", "Română", "Français"];
const LanguagesCountry = ["gb", "ro", "fr"];

type LanguageInfo = {
  code: LanguageType;
  label: string;
  country: string;
};

export const getLanguage: (lang: string) => LanguageInfo = (lang) => {
  const language = lang as LanguageType;
  const idx = Languages.indexOf(language);
  return {
    code: language,
    label: LanguagesInfo[idx],
    country: LanguagesCountry[idx],
  };
};

i18next
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json",
    },
    fallbackLng: Languages[0],
    debug: false,
    // debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
    supportedLngs: Languages,

    ns: ["translation", "form", "settings", "category"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18next;
