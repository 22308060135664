import * as React from "react";
import { styled } from "@mui/material/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToggleButton, ToggleButtonGroup, Paper } from "@mui/material";
import { BsArrowLeftRight } from "react-icons/bs";
import { FaMoneyBillWaveAlt } from "react-icons/fa";
import { SiSimpleanalytics } from "react-icons/si";

const Floating = styled(Paper)({
  position: "fixed",
  bottom: 8,
  left: "50vw",
  marginLeft: (-48 * 3) / 2,
  zIndex: 2,
});

type ValueType = "finances" | "transactions" | "analytics";

const MobileAppBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const path = location.pathname.split("/")[1] as ValueType;
  const [value, setValue] = React.useState(path);

  React.useEffect(() => {
    const newPath = location.pathname.split("/")[1] as ValueType;
    if (newPath) setValue(newPath);
  }, [location.pathname]);

  const ITEMS = [
    {
      id: "finances",
      tooltip: t("finances"),
      icon: <FaMoneyBillWaveAlt />,
    },
    {
      id: "transactions",
      tooltip: t("transactions"),
      icon: <BsArrowLeftRight />,
    },
    {
      id: "analytics",
      tooltip: t("analytics"),
      icon: <SiSimpleanalytics />,
    },
  ];

  const onChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (!newValue) return;
    setValue(newValue as ValueType);
    history.push("/" + newValue);
  };

  return (
    <Floating>
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={value}
        onChange={onChange}
        aria-label="app sections"
        size="large"
      >
        {ITEMS.map((i) => (
          <ToggleButton key={i.id} value={i.id} aria-label={i.tooltip}>
            {i.icon}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Floating>
  );
};

export default MobileAppBar;
