import { CurrencyType } from "../core/types";
import { get } from "./list";

export const symbol = (currency: string) =>
  get().currencies.find((c) => c.code === currency)?.symbol || currency;

export const description = (currency: CurrencyType) =>
  get().currencies.find((c) => c.code === currency)?.description ||
  "Unknown currency";

export const amount = (value: number, fractionary = false) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: fractionary ? 2 : 0,
    maximumFractionDigits: fractionary ? 2 : 0,
  });
};

export type SignOptions = boolean | "if-negative";
export const amountCurrency = (
  amountValue: number,
  currency: CurrencyType,
  fractionary = false,
  sign: SignOptions = false
) => {
  const value = Math.abs(amountValue);
  const out = symbol(currency) + amount(value, fractionary);

  if (sign) {
    if (amountValue === 0) return out;
    if (amountValue > 0) {
      if (sign === "if-negative") return out;
      return "+" + out;
    }
    return "-" + out;
  }

  return out;
};

const formatter = {
  amount,
  amountCurrency,
  symbol,
  description,
};

export default formatter;
