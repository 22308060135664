import React from "react";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Route, PublicRoute } from "./components/auth";
import FirebaseProvider from "./services/firebase/Provider";
import Provider from "./components/Provider";
import PagesRoot from "./components/page/PagesRoot";
import ErrorBoundary from "./ErrorBoundary";
import RedirectPage from "./components/page/RedirectPage";

const HomePage = React.lazy(() => import("./pages/Home"));
const OverviewPage = React.lazy(() => import("./pages/overview/Overview"));
const AccountsPage = React.lazy(
  () => import("./pages/finances/accounts/Accounts")
);
const TermsPage = React.lazy(() => import("./pages/Terms"));
const PrivacyPage = React.lazy(() => import("./pages/Privacy"));

const AuthPage = React.lazy(() => import("./pages/Auth"));
const SettingsPage = React.lazy(() => import("./pages/settings/Settings"));

const AnalyticsPage = React.lazy(() => import("./pages/Analytics"));

const PublicPortfolioPage = React.lazy(
  () => import("./pages/finances/public/PublicPortfolio")
);

const PortfolioPage = React.lazy(
  () => import("./pages/finances/portfolio/Portfolio")
);
const PassiveIncomePage = React.lazy(
  () => import("./pages/finances/portfolio/PassiveIncome")
);
const TaxesPage = React.lazy(() => import("./pages/finances/taxes/Taxes"));
const TradesPage = React.lazy(() => import("./pages/finances/trades/Trades"));

const TransactionsOverviewPage = React.lazy(
  () => import("./pages/transactions/overview/Overview")
);
const TransactionsManageRecurrentTransactionPage = React.lazy(
  () => import("./pages/transactions/recurrent/ManageRecurrentTransaction")
);
const TransactionsRecurrentPage = React.lazy(
  () => import("./pages/transactions/recurrent/Recurrent")
);
const TransactionsCalendarPage = React.lazy(
  () => import("./pages/transactions/calendar/Calendar")
);

const ManageAccountPage = React.lazy(
  () => import("./pages/finances/accounts/ManageAccount")
);

const GmailPage = React.lazy(() => import("./pages/integrations/gmail/Gmail"));
const EtoroPage = React.lazy(() => import("./pages/integrations/etoro/Etoro"));

const App: React.FC = () => (
  <ErrorBoundary>
    <FirebaseProvider>
      <Provider>
        <Router history={history}>
          <PagesRoot>
            <Switch>
              <PublicRoute path="/auth" component={AuthPage} />
              <PublicRoute path="/privacy" component={PrivacyPage} />
              <PublicRoute path="/terms" component={TermsPage} />

              <PublicRoute
                path="/temp/portfolio/:id"
                component={PublicPortfolioPage}
              />

              <Route path="/overview" component={OverviewPage} />

              <Route
                path="/transactions/overview"
                component={TransactionsOverviewPage}
              />
              <Route
                path="/transactions/recurrent/:id"
                component={TransactionsManageRecurrentTransactionPage}
              />
              <Route
                path="/transactions/recurrent"
                component={TransactionsRecurrentPage}
              />
              <Route
                path="/transactions/calendar"
                component={TransactionsCalendarPage}
              />

              <Route
                path="/transactions"
                component={() => <RedirectPage to="/transactions/overview" />}
              />

              <Route path="/finances/account/:id" component={AccountsPage} />
              <Route
                path="/finances/accounts/:id"
                component={ManageAccountPage}
              />
              <Route path="/finances/accounts" component={AccountsPage} />

              <Route path="/finances/portfolio" component={PortfolioPage} />
              <Route
                path="/finances/passive-income"
                component={PassiveIncomePage}
              />
              <Route path="/finances/taxes" component={TaxesPage} />
              <Route path="/finances/trades" component={TradesPage} />
              <Route
                path="/finances"
                component={() => <RedirectPage to="/finances/accounts" />}
              />

              <Route path="/settings/:tab" component={SettingsPage} />
              <Route
                path="/settings"
                component={() => <RedirectPage to="/settings/general" />}
              />
              <Route path="/integrations/gmail" component={GmailPage} />
              <Route path="/integrations/etoro" component={EtoroPage} />

              <Route path="/analytics" component={AnalyticsPage} />
              <PublicRoute path="/" component={HomePage} />
            </Switch>
          </PagesRoot>
        </Router>
      </Provider>
    </FirebaseProvider>
  </ErrorBoundary>
);

const history = createBrowserHistory();
history.listen(() => {
  setTimeout(() => {
    if (history.action === "POP") return;
    window.scrollTo(0, 0);
  });
});

export default App;
