import React from "react";
import PageContent from "./PageContent";
import { FinAppBarProps } from "./nav";
import PageLoading from "./PageLoading";

export const PagesContext = React.createContext<PagesContextInfo>(
  (context: any) => undefined
);

type PagesContextInfo = (
  context: Partial<PagesContextType> | ((context: PagesContextType) => PagesContextType)
) => any;
export type PagesContextType = {
  tabs?: boolean;
  /** Inside margin */
  margin?: "none" | "normal";
  /** Document title */
  title?: string | (string | undefined)[];

  appbar?: FinAppBarProps;
  color?: string;

  /** True if the page is a full editing page */
  editable?: boolean;
};

type Props = {
  children: React.ReactNode;
};

const PagesRoot: React.FC<Props> = ({ children }) => {
  const [context, setContext] = React.useState<PagesContextType>({});

  return (
    <PagesContext.Provider value={setContext}>
      <PageContent {...context}>
        <React.Suspense fallback={<PageLoading />}>
          {children}
        </React.Suspense>
      </PageContent>
    </PagesContext.Provider>
  );
};

export default PagesRoot;
